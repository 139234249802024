<template>
    <div class="max-w-lg mx-auto flex flex-col items-center justify-center min-h-screen">
        <Title
            class="text-center"
            :title="successTitle"
            :subtitle="successSubtitle"
        />

        <div v-if="fromRegister === 'true'">
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('ongNameFieldLabel') }}:</span>&nbsp;{{this.signupData.ngo_name}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('ongCUIFieldLabel') }}:</span>&nbsp;{{this.signupData.cif}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{$t('ongRegistrationFieldLabel')}}:</span>&nbsp;{{this.signupData.registration_number}}</p>
            <p class="text-gray-800 opacity-85 mb-10">
                <span class="font-titillium-bold">{{$t('servicesFieldLabel')}}:</span>&nbsp;
                <span v-for="(service, index) in dataServices" :key="index">{{ service.name }},&nbsp;</span>
            </p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('addresCountyPlaceholder') }}:</span>&nbsp;{{this.county.name}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('addressCityPlaceholder') }}:</span>&nbsp;{{this.city.name}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('addressCountyFieldLabel') }}:</span>&nbsp;{{this.signupData.address}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('websiteFieldLabel') }}:</span>&nbsp;{{this.signupData.website}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('descriptionFieldLabel') }}:</span>&nbsp;{{this.signupData.description}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('observationsFieldLabel') }}:</span>&nbsp;{{this.signupData.observations}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('ongManagerName') }}:</span>&nbsp;{{this.signupData.user_name}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('ongManagerEmail') }}:</span>&nbsp;{{this.signupData.user_email}}</p>
            <p class="text-gray-800 opacity-85 mb-10"><span class="font-titillium-bold">{{ $t('ongManagerPhone') }}:</span>&nbsp;{{this.signupData.user_phone}}</p>
        </div>

        <div class="flex items-center justify-center mt-36">
            <a
                :href="link"
                target="_blank"
                class="button bg-indigo-400 text-white hover:bg-indigo-300 flex items-center justify-center"
            >
                {{ $t('successMessageButtonText') }}
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Title from '@/components/texts/FormTitle'

export default {
    name: 'Success',

    props: ['successTitle', 'successSubtitle', 'signupData', 'fromRegister'],

    components: {
        Title
    },

    data () {
        return {
            link: process.env.VUE_APP_WEBSITE,
            county: '',
            city: '',
            dataServices: []
        }
    },

    computed: {
        /** Get vuex state. */
        ...mapGetters(['activationCode', 'token', 'userType', 'counties', 'cities', 'services'])
    },

    methods: {
        ...mapActions(['fetchCounties', 'fetchCities', 'fetchServices'])
    },

    async mounted () {
        /** Extract county. */
        await this.fetchCounties().then(() => {
            this.county = this.counties.find((county) => county.id === this.signupData.county_id)
        })

        /** Extract city. */
        await this.fetchCities(this.signupData.county_id).then(() => {
            this.city = this.cities.find((county) => county.id === this.signupData.city_id)
        })

        /** Extract sercives. */
        await this.fetchServices().then(() => {
            this.services.forEach((service) => {
                this.signupData.services.forEach((savedService) => {
                    if (savedService === service.id) {
                        this.dataServices.push({ ...service, ...savedService })
                    }
                })
            })
        })
    }
}
</script>
